.editor {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 8px;
    border-radius: 2px;
    background: #fefefe;
    border-radius: 10px;
    height: fit-content;
    border: 1px solid #686868;
    font-size: 14px;
  }

  .editor :global(.public-DraftEditor-content) {
    min-height: 70px;
    width: 100%;
  }
