.react-tabs__tab {
    display: inline-block;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 1.7rem;
    background-color: #E5E5E5;
    color: #868686;
    border-radius: 12px 12px 0 0;
    font-weight: bold;

    &--selected {
        background: #F9F9F9 !important;
        color: #4184FF !important;
        border-radius: 12px 12px 0px 0px !important;
        border-color: transparent !important;
    }
}

.react-tabs__tab--inner {
    display: inline-block;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 1.7rem;
    background-color: #E5E5E5;
    color: #868686;
    border-radius: 12px 12px 0 0;
    font-weight: bold;
    width: 100%;
    background: #F9F9F9;
    max-width: 50%;

    &--selected {
        background: #F9F9F9 !important;
        color: #4184FF !important;
        border-radius: 12px 12px 0px 0px !important;
        border-color: transparent !important;
        background: #FFFFFF !important;
    }
}

.react-tabs__tab-list--inner {
    border-bottom: none !important;
    padding: 1rem .5rem 0 .5rem !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-block-start: 0 !important;
    margin-block-end: 0 !important;
}

.react-tabs__tab-list--workspace {
    //display: flex;
    align-items: flex-end;
    // height: 30vh;
   // padding-top: 10rem;
   // margin-block-start: 0;
   // padding-inline-start: 32px;
   margin-bottom: 0px;
}


@media (min-width: 1100px) { //for desktops only
    .react-tabs__tab-list--workspace {
        //display: flex;
        align-items: flex-end;
          height: 10vh;
        //padding-top: 10rem;
        margin-block-start: 0;
        padding-inline-start: 32px;
    }

}
