@mixin dropDownOptionsGeneric {
  border: 1px solid #f9f9f9;
  border-radius: 10px;
}

.dropDownOptions {
  @include dropDownOptionsGeneric;
}

.dropDownOptionsColors {
  @include dropDownOptionsGeneric;
}

.ant-select-selection-placeholder {
  font-size: 1.4rem !important;
  color: rgba(0, 0, 0, 0.87) !important;
} 