#root {
  height: 100%;
  background-color: #ffffff;
}

/* Map Info container vertical animation*/
.map-info-enter {
  height: 0%;
}

.map-info-enter-active {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 25%;
}

.map-info-enter-done {
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  height: 50%;
}

.map-info-exit .infoContainer {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  height: 50%;
  overflow: hidden;
}

.map-info-exit-active .infoContainer {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  height: 25%;
  overflow: hidden;
}

.map-info-exit-done .infoContainer {
  height: 0%;
  overflow: hidden;
}

.mobile-responsive-dashboard-trip-card {
  .MuiCardContent-root:last-child {
    padding-bottom: 10px !important;
  }
}

/* Map Horizontal animation*/
.map-enter {
  opacity: 0;
  width: 0%;
}

.map-enter-active {
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
  opacity: 0.5;
  width: 20%;
}

.map-enter-done {
  opacity: 1;
  width: 40%;
}

.map-exit {
  opacity: 1;
  width: 40%;
}

.map-exit-active {
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  opacity: 0.5;
  width: 20%;
}

.map-exit-done {
  opacity: 0;
  width: 0%;
}

.ant-picker-dropdown {
  z-index: 10000 !important;
  top: 790px;
}

.mde-text {
  font-size: 14px;
}

.gmnoprint {
  display: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 6px !important;
  height: 45px !important;
  padding: 6px 12px !important;
}

.hide {
  display: none !important;
}
#__EDDY_TRAVELS_CHAT_WIDGET {
  right: 32px !important;
}
#__EDDY_TRAVELS_CHAT_OPEN_BUTTON:not(:empty) {
  border-radius: 50px;
  box-shadow: 0 0 10px 0px rgba(65, 132, 255, 0.75);
}

.trip-to-card-dates > div {
  width: 100%;
}

/* Scrollbar */
*::-webkit-scrollbar {
  width: 4px !important; /* width of the vertical scrollbar */
  height: 4px !important; /* height of the horizontal scrollbar */
}

/* Track */
*::-webkit-scrollbar-track {
  background: #ebe9e9 !important; /* color of the track */
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #a8a5a5 !important; /* color of the scroll thumb */
  border-radius: 5px !important; /* roundness of the scroll thumb */
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #888 !important; /* color of the scroll thumb on hover */
}

/* Handle when window is inactive */
::-webkit-scrollbar-thumb:window-inactive {
  background: #888;
}

.MuiPaginationItem-root {
  height: 40px !important;
  width: 40px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  border: 1px solid #e5e5e5 !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 22px !important;
}

.MuiPaginationItem-page.Mui-selected {
  color: #0545ba !important;
  border: 1px solid #0545ba !important;
  background-color: #ffffff !important;
}

.MuiSvgIcon-root MuiPaginationItem-icon {
  height: 30px !important;
  width: 30px !important;
}

.MuiPaginationItem-icon {
  height: 24px !important;
  width: 24px !important;
}
