.poll {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: inherit;
    text-align: center;
  }

  .poll * {
    box-sizing: border-box;
  }

  .poll .question {
    color: #3a3a3a;
    text-align: inherit;
    font-weight: normal;
    margin: 0;
    padding-bottom: 3px;
    border-bottom: 1px solid #6d4b943b;
  }
  .poll .answers {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    margin: 5px 0;
  }

  .poll .answers li {
    width: 100%;
    margin: 5px 0;
  }

  .poll .answers .option, .poll .answers .result {
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    padding: 10px;
    transition: all 0.3s ease;
    outline: 0;
    font-family: inherit;
    background-color: inherit;
    color: #3A3A3A;
    border-color: #E5E5E5;

    &:hover {
      cursor: pointer;
    }
  }

  .poll .answers .option:hover, .poll .answers .option:active {
      background: #3030303B;
  }

  .poll .answers .result {
    height: 42px;
    padding: 0;
    cursor: default;
    position: relative;
  }

  .poll .answers .result .fill {
    width: 0%;
    height: 100%;
    display: flex;
    align-items: center;
    transition: all 0.5s ease;
    background-color: #E5E5E5;
    border-radius: 10px;
  }

  .poll .answers .result .labels {
    position: absolute;
    top: calc(19px - 9px);
    margin: 0 5px;
    font-size: 12px;
    color: #3A3A3A;
    text-align: initial;

    /* add this into its child div for poll options --- overflow-wrap: anywhere; */
  }

  .labels > span {
    overflow-wrap: anywhere;
  }

  // .modal {
  //   overflow-y: auto;
  //  }

  //  .modal-open {
  //   overflow: auto;
  //  }

  .poll .answers .result .answer {
    margin-left: 15px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .poll .answers .result .answer.vote::after {
    content: ' \2713';
  }

  .poll .votes {
    text-align: inherit;
    margin: 0;
    padding: 0;
    font-size: 13px;
    color: #3030305e;
  }


